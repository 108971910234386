/* eslint-disable */
import createTemplate from './createTemplate';
import formatData from './formatData';

export const initForm = (formId, params) => {
    // Select the table
    let div = document.getElementById(formId);
    // Get the URL from the table's data attributes
    let url = div.dataset.url;
    let urlParams = new URLSearchParams(window.location.search);
    let id = "";
    let newUrl = url;
    if (params) {
        id = params.id ? params.id : urlParams.get('id') || 'new';
        newUrl = url.replace('{id}', id).replace('{search_str}', params.search_str);
    }

    // Fetch data from the URL
    fetch(newUrl, { credentials: 'include' })
        .then(response => response.json())
        .then(data => {
            //console.log("FORM");
            // Use the data to generate the item list
            let itemHtml = "";
            div.querySelectorAll('input').forEach(input => {
                let source = input.dataset.source;
                let source_group = input.dataset.source_group;
                if (source !== undefined) {
                    // Check if the source contains a dot for nested keys
                    if (source_group && data[source_group]) {
                        let value = input.dataset.default_value || data[source_group][source] || "";
                        if (value !== undefined) {
                            itemHtml = formatData(source, value, input.dataset);
                            input.value = itemHtml;
                        }
                    } else {
                        let value = input.dataset.default_value || data[source] || "";
                        if (value !== undefined) {
                            itemHtml = formatData(source, value, input.dataset);
                            input.value = itemHtml;
                        }
                    }
                }
            });
        })
        .catch(error => {
            console.error(error);
        });
};

export default initForm;
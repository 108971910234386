/* eslint-disable */
import createTemplate from './createTemplate';
import formatData from './formatData';

// Add development check
const isDevelopment = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';

export const initForm = (formId, params) => {
    // Select the table
    let div = document.getElementById(formId);
    // Get the URL from the table's data attributes
    let url = div.dataset.url;
    let urlParams = new URLSearchParams(window.location.search);
    let id = "";
    let newUrl = url;
    if (params?.id || urlParams?.get('id')) {
        id = params?.id || urlParams?.get('id') || 'new';
        newUrl = url.replace('{id}', id);
        if (params?.search_str) {
            newUrl = newUrl.replace('{search_str}', params.search_str);
        }
    }

    const getData = async () => {
        if (isDevelopment) {
            try {
                const urlParts = newUrl.split('/').filter(part => part.length > 0);
                const endpoint = urlParts[urlParts.length - 1]?.split('?')[0] || urlParts[urlParts.length - 2];
                const jsonFile = endpoint === 'clients' ? 'client' : endpoint;
                const response = await fetch(`/dummy-data/${jsonFile}.json`);
                return response.json();
            } catch (error) {
                console.warn('Could not load dummy form data:', error);
                return {}; // Return empty object as fallback
            }
        }
        
        // Production fetch
        const response = await fetch(newUrl, { credentials: 'include' });
        return response.json();
    };

    getData()
        .then(data => {
            //console.log("FORM");
            // Use the data to generate the item list
            let itemHtml = "";
            div.querySelectorAll('input').forEach(input => {
                let source = input.dataset.source;
                let source_group = input.dataset.source_group;
                if (source !== undefined) {
                    // Check if the source contains a dot for nested keys
                    if (source_group && data[source_group]) {
                        let value = input.dataset.default_value || data[source_group][source] || "";
                        if (value !== undefined) {
                            itemHtml = formatData(source, value, input.dataset);
                            input.value = itemHtml;
                        }
                    } else {
                        let value = input.dataset.default_value || data[source] || "";
                        if (value !== undefined) {
                            itemHtml = formatData(source, value, input.dataset);
                            input.value = itemHtml;
                        }
                    }
                }
            });
        })
        .catch(error => {
            console.error(error);
        });
};

export default initForm;
/* eslint-disable */
import formatData from './formatData';

//const createTemplate = (template_key, field_id, value, object_type, object_class, object_link) => {
const createTemplate = (field_id, value, dataset, object_type, object_class) => {
        //let formattedValue = formatData(template_key, field_id, value, object_link);
    let formattedValue = formatData(field_id, value, dataset);
    object_class = object_class.split('.').join(' ');
    const formatToTemplate = {
        "default": `<${object_type} class="${field_id} ${object_class}" data-source="${field_id}">${formattedValue}</${object_type}>`,
        "number": `<${object_type} class="${field_id} ${object_class}" data-source="${field_id}">${formattedValue}</${object_type}>`,
        "link": `<${object_type} class="${field_id} ${object_class}" data-source="${field_id}">${formattedValue}</${object_type}>`,
    };

    return formatToTemplate[dataset.template] || formatToTemplate["default"];
};

export default createTemplate;

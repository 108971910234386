/* eslint-disable */
import createTemplate from './createTemplate';
import formatData from './formatData';

export const setForm = (button, targetFormId) => {
    if (!button || !targetFormId) {
        return;
    }
    
    let row = button.closest('tr');
    if (!row) {
        return;
    }
    let data = {};
    row.querySelectorAll('td').forEach(td => {
        let key = td.dataset.source;
        let value = td.innerText;
        if (key) {
            data[key] = value;
        }
    });

    let div = document.getElementById(targetFormId);

    let itemHtml = "";
    div.querySelectorAll('input').forEach(input => {
        let source = input.dataset.source;
        if (source !== undefined) {
            if (data[source] !== undefined) {
                let value = input.dataset.default_value || data[source] || "";
                itemHtml = formatData(source, value, input.dataset);
                input.value = itemHtml;
            }
        }
    });

    /*
    Object.keys(rowData).forEach(key => {
        const input = div.querySelector(`input[data-source="${key}"]`);
        if (input) {
            input.value = rowData[key];
        }
    });
    */
};

export default setForm;
/* eslint-disable */

//const formatData = (template, field_id, value, object_link) => {
const formatData = (field_id, value, dataset) => {
    let template = dataset.format || field_id;
    let template_key = template;
    let template_extra = "";
    if (template && template.includes("-")) {
        template_key = template.split("-")[0];
        template_extra = template.split("-")[1];
    }

    let link = "";
    if (dataset.link) {
        link = dataset.link.replace("{id}", value);
    }

    const statusBadge = {
        "primary": `<span class="badge badge-phoenix fs-10 badge-phoenix-primary"><span class="badge-label">${value}</span><span class="ms-1" data-feather="${dataset.status_icon}" style="height:12.8px;width:12.8px;"></span></span>`,
        "secondary": `<span class="badge badge-phoenix fs-10 badge-phoenix-secondary"><span class="badge-label">${value}</span><span class="ms-1" data-feather="${dataset.status_icon}" style="height:12.8px;width:12.8px;"></span></span>`,
        "success": `<span class="badge badge-phoenix fs-10 badge-phoenix-success"><span class="badge-label">${value}</span><span class="ms-1" data-feather="${dataset.status_icon}" style="height:12.8px;width:12.8px;"></span></span>`,
        "info": `<span class="badge badge-phoenix fs-10 badge-phoenix-info"><span class="badge-label">${value}</span><span class="ms-1" data-feather="${dataset.status_icon}" style="height:12.8px;width:12.8px;"></span></span>`,
        "warning": `<span class="badge badge-phoenix fs-10 badge-phoenix-warning"><span class="badge-label">${value}</span><span class="ms-1" data-feather="${dataset.status_icon}" style="height:12.8px;width:12.8px;"></span></span>`,
        "danger": `<span class="badge badge-phoenix fs-10 badge-phoenix-danger"><span class="badge-label">${value}</span><span class="ms-1" data-feather="${dataset.status_icon}" style="height:12.8px;width:12.8px;"></span></span>`
    };

    const statusBadgeMap = {
        "PRIMARIO": statusBadge.primary,
        "PENDIENTE": statusBadge.secondary,
        "PAGADO": statusBadge.success,
        "FACTURADO": statusBadge.info,
        "ALERTA": statusBadge.warning,
        "CANCELADO": statusBadge.danger
    };

    const formatToData = {
        "default": value,
        "number": isNaN(value) ? value : Number(value).toLocaleString(),
        "link": `<a href="${link}">${value}</a>`,
        "link_new_tab": `<a href="${link}" target="_blank">${value}</a>`,
        "select_action": `<button class="btn btn-sm btn-primary" type="button" onclick="initForm('${template_extra}', {id:'${value}'})">Seleccionar</button>`,
        "edit_delete":
            `<div class="btn-reveal-trigger position-static"><button class="btn btn-sm dropdown-toggle dropdown-caret-none transition-none btn-reveal fs-10" type="button" data-bs-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false" data-bs-reference="parent"><span class="fas fa-ellipsis-h fs-10"></span></button>
                <div class="dropdown-menu dropdown-menu-end py-2">
                    <button type="button" class="dropdown-item" onclick="setForm(this, '${template_extra}')">Editar</button>
                    <button type="button" class="dropdown-item text-danger" onclick="initForm('${template_extra}', {id:'${value}'})">Borrar</button>
                </div>
            </div>`,
        "edit_delete_offcanvas":
            `<div class="btn-reveal-trigger position-static"><button class="btn btn-sm dropdown-toggle dropdown-caret-none transition-none btn-reveal fs-10" type="button" data-bs-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false" data-bs-reference="parent"><span class="fas fa-ellipsis-h fs-10"></span></button>
                <div class="dropdown-menu dropdown-menu-end py-2">
                    <button type="button" class="dropdown-item" onclick="setForm(this, '${template_extra}')" data-bs-toggle="offcanvas" data-bs-target="#${link}">Editar</button>
                    <button type="button" class="dropdown-item text-danger" onclick="tableCRUD('delete', '${dataset.delete_target}', '${dataset.delete_source}', '${value}')">Borrar</button>
                </div>
            </div>`,
        "status": statusBadgeMap[value] || statusBadge.primary
    };

    return formatToData[template_key] || formatToData["default"];
};

export default formatData;

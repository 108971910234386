/* eslint-disable */

export function handleComplexSubmission(form_id) {
    document.getElementById(form_id).addEventListener('submit', function(event) {
        event.preventDefault(); // Prevent the form from submitting normally

        // Initialize data object
        let data = {};
        const mappings = JSON.parse(this.dataset.mappings);

        // Iterate through each mapping configuration
        mappings.forEach(mapping => {
            if (mapping.type === 'form') {
                let data_group = {};
                let form = document.getElementById(mapping.source);
                let formData = new FormData(form);
                for (const [key, value] of formData.entries()) {
                    data_group[key] = value;
                }
                data[mapping.target] = data_group;
            } else if (mapping.type === 'table') {
                let data_group = [];
                let table = document.getElementById(mapping.source);
                let rows = table.querySelectorAll('tbody tr');
                rows.forEach(row => {
                    let row_data = {};
                    let cells = row.querySelectorAll('td');
                    cells.forEach(cell => {
                        let source = cell.dataset.source;
                        if (source !== undefined && source !== "undefined") {
                            let value = cell.innerText;
                            row_data[source] = value;
                        }
                    });
                    data_group.push(row_data);
                });
                data[mapping.target] = data_group;
            } else if (mapping.type === 'div') {
                let data_group = {};
                let div = document.getElementById(mapping.source);
                div.querySelectorAll('input[data-source]').forEach(input => {
                    data_group[input.dataset.source] = input.value;
                });
                data[mapping.target] = data_group;
            }
        });

        // Log the data being sent for debugging
        console.log('Data being sent:', data);

        // Make the POST request
        let main_div = this.querySelector('.form-init');
        let success_message = main_div.dataset.successmsg;
        let success_redirect = main_div.dataset.successredirect;
        let error_message = main_div.dataset.errormsg;
        let url = main_div.dataset.url;
        let urlParams = new URLSearchParams(window.location.search);
        let id = urlParams.get('id') || 'new';
        let newUrl = url.replace('{id}', id);
        console.log(newUrl);
        fetch(newUrl, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data, (key, value) => value === "" ? null : value),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
            if (success_message) {
                document.getElementById('successToastBody').textContent = success_message;
                const successToast = document.getElementById('successToast');
                const bsSuccessToast = new bootstrap.Toast(successToast);
                bsSuccessToast.show();
            }
            if (success_redirect) {
                window.location.href = success_redirect + "?successmsg=" + success_message;
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            if (error_message) {
                document.getElementById('errorToastBody').textContent = error_message;
                const errorToast = document.getElementById('errorToast');
                const bsErrorToast = new bootstrap.Toast(errorToast);
                bsErrorToast.show();
            }
            if (success_redirect) {
                window.location.href = success_redirect + "?errormsg=" + error_message;
            }
        });
    });
}

export default handleComplexSubmission;
/* eslint-disable */
import createTemplate from './createTemplate';

export const initTable = async (tableId, params) => {
    console.log('initTable called with:', { tableId, params });

    const togglePaginationButtonDisable = (button, disabled) => {
        if (button != null) {
            button.disabled = disabled;
            button.classList[disabled ? "add" : "remove"]("disabled");
        }
    };
    // Select the table
    let div = document.getElementById(tableId);
    let table = div.querySelector("table");
    // Get the URL from the table's data attributes
    let url = table.dataset.url;
    let urlParams = new URLSearchParams(window.location.search);
    let id = "";
    let newUrl = url;
    if (params) {
        id = params.id ? params.id : urlParams.get('id') || 'new';
        newUrl = url.replace('{id}', id).replace('{search_str}', params.search_str);
    }

    // Clear the table before loading new data
    while (table.rows.length > 1) {
        table.deleteRow(1);
    }
    let row = document.createElement("tr");
    let placeholder = document.createElement("span");
    placeholder.classList.add("placeholder-glow");
    placeholder.innerHTML = "<span class='placeholder bg-primary placeholder-lg col-12'></span>";
    let tHeadColumnsCount = table.rows[0].cells.length;
    for (let i = 0; i < tHeadColumnsCount; i++) {
        let cell = document.createElement("td");
        cell.appendChild(placeholder.cloneNode(true));
        row.appendChild(cell);
    }
    table.appendChild(row.cloneNode(true));
    table.appendChild(row.cloneNode(true));
    table.appendChild(row.cloneNode(true));
    table.appendChild(row.cloneNode(true));
    table.appendChild(row.cloneNode(true));

    console.log('Fetching data from:', newUrl);
    fetch(newUrl, { credentials: 'include' })
        .then(response => {
            console.log('Response received:', response.status);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            console.log('Data received:', data);
            //console.log(data);
            // Clear the table before loading new data
            while (table.rows.length > 1) {
                table.deleteRow(1);
            }
            // Use the data to generate the item list
            let options = {
                page: 10,
                pagination: {
                    item: "<li><button class='page' type='button'></button></li>"
                },
                item: values => {
                    let itemHtml = '<tr>';
                    table.querySelectorAll('th').forEach(th => {
                        let source = th.dataset.source;
                        let value = th.dataset.default_value || values[th.dataset.delete_source] || values[source] || "";
                        let object_class = th.dataset.class || "";
                        itemHtml += createTemplate(source, value, th.dataset, "td", object_class);
                    });
                    itemHtml += "</tr>";
                    return itemHtml;
                }
            };
            // TODO: Summarize table data
            let paginationButtonNext = div.querySelector(
                "[data-list-pagination='next']"
            );
            let paginationButtonPrev = div.querySelector(
                "[data-list-pagination='prev']"
            );
            let viewAll = div.querySelector('[data-list-view="*"]');
            let viewLess = div.querySelector('[data-list-view="less"]');
            let listInfo = div.querySelector('[data-list-info]');
            let listFilter = document.querySelector('[data-list-filter]');

            //console.log("div: ", div);
            //console.log("table: ", table);
            //console.log("options: ", options);
            //console.log("data: ", data);
            let orderList = new window.List(div, options, data);
            //console.log("orderList: ", orderList);

            if (table.dataset.summarize) {
                // Create or update the summary row in the tfoot
                let tfoot = table.querySelector('tfoot');
                if (!tfoot) {
                    tfoot = document.createElement('tfoot');
                    table.appendChild(tfoot);
                }
                tfoot.innerHTML = ''; // Clear existing summary rows

                if (orderList.items.length > 0) {
                    // Summarize logic
                    let summaryRow = document.createElement('tr');

                    table.querySelectorAll('th').forEach(th => {
                        let key = th.getAttribute('data-source');
                        let operation = th.dataset.summarize || ''; // Get the summarization operation from data-summarize
                        let cell = document.createElement('td');
                        let class_name = th.dataset.class || "";
                        if (class_name) {
                            cell.classList.add(class_name);
                        }

                        // Ensure values are parsed as numbers
                        let columnValues = orderList.items.map(item => parseFloat(item._values[key]) || 0);
                        switch (operation) {
                            case 'sum':
                                cell.textContent = columnValues.reduce((a, b) => a + b, 0);
                                break;
                            case 'count':
                                cell.textContent = columnValues.length;
                                break;
                            case 'avg':
                                cell.textContent = columnValues.length ? (columnValues.reduce((a, b) => a + b, 0) / columnValues.length) : 0;
                                break;
                            case 'label':
                                cell.textContent = 'Total'; // or any specific label you want
                                break;
                            default:
                                cell.textContent = ''; // Default case if operation is unknown
                        }
                        summaryRow.appendChild(cell);
                    });

                    tfoot.appendChild(summaryRow); // Add the summary row to the tfoot
                }
            }

            // Fallback
            orderList.on('updated', item => {
                const fallback =
                    div.querySelector('.fallback') ||
                    document.getElementById(options.fallback);

                if (fallback) {
                    if (item.matchingItems.length === 0) {
                        fallback.classList.remove('d-none');
                    } else {
                        fallback.classList.add('d-none');
                    }
                }
            });

            let totalItem = orderList.items.length;
            let itemsPerPage = orderList.page;
            let btnDropdownClose = orderList.listContainer.querySelector('.btn-close');
            let pageQuantity = Math.ceil(totalItem / itemsPerPage);
            let numberOfcurrentItems = orderList.visibleItems.length;
            let pageCount = 1;

            btnDropdownClose &&
                btnDropdownClose.addEventListener('search.close', () =>
                    orderList.fuzzySearch('')
                );

            let updateListControls = () => {
                listInfo &&
                    (listInfo.innerHTML = `${orderList.i} to ${numberOfcurrentItems} of ${totalItem}`);
                paginationButtonPrev &&
                    togglePaginationButtonDisable(paginationButtonPrev, pageCount === 1);
                paginationButtonNext &&
                    togglePaginationButtonDisable(
                        paginationButtonNext,
                        pageCount === pageQuantity
                    );

                if (pageCount > 1 && pageCount < pageQuantity) {
                    togglePaginationButtonDisable(paginationButtonNext, false);
                    togglePaginationButtonDisable(paginationButtonPrev, false);
                }
            };
            updateListControls();

            if (paginationButtonNext) {
                paginationButtonNext.addEventListener('click', e => {
                    e.preventDefault();
                    pageCount += 1;

                    const nextInitialIndex = orderList.i + itemsPerPage;
                    nextInitialIndex <= orderList.size() &&
                        orderList.show(nextInitialIndex, itemsPerPage);
                    numberOfcurrentItems += orderList.visibleItems.length;
                    updateListControls();
                });
            }

            if (paginationButtonPrev) {
                paginationButtonPrev.addEventListener('click', e => {
                    e.preventDefault();
                    pageCount -= 1;

                    numberOfcurrentItems -= orderList.visibleItems.length;
                    const prevItem = orderList.i - itemsPerPage;
                    prevItem > 0 && orderList.show(prevItem, itemsPerPage);
                    updateListControls();
                });
            }

            const toggleViewBtn = () => {
                viewLess.classList.toggle('d-none');
                viewAll.classList.toggle('d-none');
            };

            if (viewAll) {
                viewAll.addEventListener('click', () => {
                    orderList.show(1, totalItem);
                    pageQuantity = 1;
                    pageCount = 1;
                    numberOfcurrentItems = totalItem;
                    updateListControls();
                    toggleViewBtn();
                });
            }
            if (viewLess) {
                viewLess.addEventListener('click', () => {
                    orderList.show(1, itemsPerPage);
                    pageQuantity = Math.ceil(totalItem / itemsPerPage);
                    pageCount = 1;
                    numberOfcurrentItems = orderList.visibleItems.length;
                    updateListControls();
                    toggleViewBtn();
                });
            }
            if (options.pagination) {
                div.querySelector('.pagination').addEventListener('click', e => {
                    if (e.target.classList[0] === 'page') {
                        pageCount = Number(e.target.innerText);
                        updateListControls();
                    }
                });
            }
            if (options.filter) {
                const { key } = options.filter;
                listFilter.addEventListener('change', e => {
                    orderList.filter(item => {
                        if (e.target.value === '') {
                            return true;
                        }
                        return item.values()[key].toLowerCase().includes(e.target.value.toLowerCase());
                    });
                });
            }
        })
        .catch(error => {
            console.error("Error fetching data:", error);
            // Handle fetch errors (e.g., display error message to user)
        });
};

export default initTable;

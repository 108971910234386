/* eslint-disable */
import initTable from './initTable';
import initCard from './initCard';
import initForm from './initForm';
import handleFormSubmission from './handleFormSubmission';
import handleComplexSubmission from './handleComplexSubmission';
import tableCRUD from './tableCRUD';
import setForm from './setForm';

console.log('initAll.js loaded');

const initAll = () => {
    const tables = document.querySelectorAll(".table-init");
    tables.forEach(table => {
        initTable(table.id, null);
    });
    const cards = document.querySelectorAll(".card-init");
    cards.forEach(card => {
        initCard(card.id, null);
    });
    const forms = document.querySelectorAll(".form-init");
    forms.forEach(form => {
        initForm(form.id, null);
    });
    const formSubmit = document.querySelectorAll(".form-submit-init");
    formSubmit.forEach(form => {
        handleFormSubmission(form.id);
    });
    const complexSubmit = document.querySelectorAll(".complex-submit-init");
    complexSubmit.forEach(form => {
        handleComplexSubmission(form.id);
    });
    // Dummy call
    tableCRUD(null, null, null);
    setForm(null, null, null);
};

export default initAll;

/* eslint-disable */
import createTemplate from './createTemplate';

export function tableCRUD(action, tableId, formId, removePkValue) {
    if (!tableId || !formId) {
        return;
    }
    let table = document.getElementById(tableId);
    let realTable = table.querySelector('table');

    // Extract values from formData
    
    let newData = {};
    if (action !== "delete") {
        let form = document.getElementById(formId);
        let formData = new FormData(form);
        formData.forEach((value, key) => {
            newData[key] = value;
        });
    }

    // Create a new list instance
    let tableHeader = table.querySelector('thead');
    let headerCells = tableHeader.querySelectorAll('th');
    let valueNames = Array.from(headerCells).map(cell => cell.getAttribute('data-source')).filter(Boolean);
    let options = {
        valueNames: valueNames,
        item: values => {
            let itemHtml = '<tr>';
            table.querySelectorAll('th').forEach(th => {
                let source = th.dataset.source;
                let value = th.dataset.default_value || values[th.dataset.delete_source] || values[source] || "";
                let object_class = th.dataset.class || "";
                itemHtml += createTemplate(source, value, th.dataset, "td", object_class);
            });
            itemHtml += "</tr>";
            return itemHtml;
        }
    };
    let tableList = new List(tableId, options);

    let pk_source = tableHeader.querySelector('th[data-pk="True"]').dataset.source;
    let pk_value = removePkValue || newData[pk_source];
    tableList.remove(pk_source, pk_value);
    if (action === "create") {
        tableList.add(newData);
    }

    // Create or update the summary row in the tfoot
    let tfoot = realTable.querySelector('tfoot');
    if (!tfoot) {
        tfoot = document.createElement('tfoot');
        realTable.appendChild(tfoot);
    }
    tfoot.innerHTML = ''; // Clear existing summary rows

    if (tableList.items.length > 0) {
        // Summarize logic
        let summaryRow = document.createElement('tr');

        table.querySelectorAll('th').forEach(th => {
            let key = th.getAttribute('data-source');
            let operation = th.dataset.summarize || ''; // Get the summarization operation from data-summarize
            let cell = document.createElement('td');
            let class_name = th.dataset.class || "";
            if (class_name) {
                cell.classList.add(class_name);
            }

            // Ensure values are parsed as numbers
            let columnValues = tableList.items.map(item => parseFloat(item._values[key]) || 0);
            switch (operation) {
                case 'sum':
                    cell.textContent = columnValues.reduce((a, b) => a + b, 0);
                    break;
                case 'count':
                    cell.textContent = columnValues.length;
                    break;
                case 'avg':
                    cell.textContent = columnValues.length ? (columnValues.reduce((a, b) => a + b, 0) / columnValues.length) : 0;
                    break;
                case 'label':
                    cell.textContent = 'Total'; // or any specific label you want
                    break;
                default:
                    cell.textContent = ''; // Default case if operation is unknown
            }
            summaryRow.appendChild(cell);
        });

        tfoot.appendChild(summaryRow); // Add the summary row to the tfoot
    }
}

export default tableCRUD;
/* eslint-disable */

export function handleFormSubmission(form_id) {
    document.getElementById(form_id).addEventListener('submit', function(event) {
        event.preventDefault(); // Prevent the form from submitting normally

        // Get the form data
        let formData = new FormData(this);
        if (formData.entries().next().done) {
            console.error('Form data is empty');
            return;
        }
        console.log(formData);

        // Convert the form data to a JSON object
        let data = {};
        for (const [key, value] of formData.entries()) {
            data[key] = value;
        }

        // Log the data being sent for debugging
        console.log('Data being sent:', data);

        // Make the POST request
        let main_div = this.querySelector('.form-init');
        let success_message = main_div.dataset.successmsg;
        let success_redirect = main_div.dataset.successredirect;
        let error_message = main_div.dataset.errormsg;
        let url = main_div.dataset.url;
        let urlParams = new URLSearchParams(window.location.search);
        let id = urlParams.get('id') || 'new';
        let newUrl = url.replace('{id}', id);
        console.log(newUrl);
        fetch(newUrl, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data, (key, value) => value === "" ? null : value),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
            if (success_message) {
                document.getElementById('successToastBody').textContent = success_message;
                const successToast = document.getElementById('successToast');
                const bsSuccessToast = new bootstrap.Toast(successToast);
                bsSuccessToast.show();
            }
            if (success_redirect) {
                window.location.href = success_redirect + "?successmsg=" + success_message;
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            if (error_message) {
                document.getElementById('errorToastBody').textContent = error_message;
                const errorToast = document.getElementById('errorToast');
                const bsErrorToast = new bootstrap.Toast(errorToast);
                bsErrorToast.show();
            }
            if (success_redirect) {
                window.location.href = success_redirect + "?errormsg=" + error_message;
            }
        });
    });
}

export default handleFormSubmission;

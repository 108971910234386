/* eslint-disable */
import createTemplate from './createTemplate';
import formatData from './formatData';

export const initCard = (cardId, params) => {
    // Select the table
    const card = document.getElementById(cardId);
    // Get the URL from the table's data attributes
    const url = card.dataset.url;
    
    var urlParams = new URLSearchParams(window.location.search);
    let id = "";
    let newUrl = url;
    if (params?.id || urlParams?.get('id')) {
        id = params?.id || urlParams?.get('id') || 'new';
        newUrl = url.replace('{id}', id);
        if (params?.search_str) {
            newUrl = newUrl.replace('{search_str}', params.search_str);
        }
    }

    // Fetch data from the URL
    fetch(newUrl, { credentials: 'include' })
        .then(response => response.json())
        .then(data => {
            //console.log("CARD");
            //console.log(data);
            // Use the data to generate the item list
            let itemHtml = "";
            card.querySelectorAll('.card-item').forEach(div => {
                //console.log(div);
                const source = div.dataset.source;
                let object_class = div.dataset.class || "";
                let value = div.dataset.default_value || data[source] || "";
                itemHtml = createTemplate(source, value, div.dataset, "div", object_class);
                div.innerHTML = itemHtml;
            });
        })
        .catch(error => {
            console.error(error);
        });
};

export default initCard;
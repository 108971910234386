import initAll from './e-cobros/initAll.js';

const { docReady } = window.phoenix.utils;

console.log('e-cobros.js loaded');

async function checkAuthStatus() {
  try {
    const response = await fetch('https://api.cooparte.app/me', { credentials: 'include' });
    const data = await response.json();
    console.log('Auth status:', data);
    return data.user ? data.user : null;
  } catch (error) {
    console.error('Error checking auth status:', error);
    return null;
  }
}

async function login() {
  window.location.href = 'https://api.cooparte.app/login';
}

async function logout() {
  window.location.href = 'https://api.cooparte.app/logout';
}

docReady(async () => {
  console.log('Document ready, starting initialization');
  try {
    const user = await checkAuthStatus();
    if (user) {
      console.log('User is authenticated:', user);
      initAll();
    } else {
      console.log('User is not authenticated, redirecting to login');
      await login();
    }
  } catch (error) {
    console.error('Error in docReady:', error);
    initAll();
  }
});

// Expose login and logout functions globally if needed
window.login = login;
window.logout = logout;
